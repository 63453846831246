import React from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import IndexLayout from '@/layouts/index'
import { GoTop, SEO } from '@/components/molecules'
import { ContactForm } from '@/components/organisms'
import { SearchConnect } from '@/components/connects'
import { useHandleResponsible } from '@/hooks'

import * as styles from './index.module.scss'

type Props = {
  data: {
    thumbnail: any
  }
}

const Contact: React.FC<Props> = ({ data }) => {
  const thumbnailPath = data.thumbnail.childImageSharp.resize.src
  const other = {
    title: 'お問い合わせ',
    description: 'お問い合わせについて',
    url: useLocation().href
  }
  const { goTopSize } = useHandleResponsible({ breakPoint: 1160 })

  return (
    <IndexLayout disableGoTop>
      <SEO type={"website"} thumbnailPath={thumbnailPath} other={other} />
      <SearchConnect>
        <ContactForm className={styles.contact} />
      </SearchConnect>
      <GoTop size={goTopSize} bottom={100} />
    </IndexLayout>
  )
}

export default Contact

export const ContactPageQuery = graphql`
  query {
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`
